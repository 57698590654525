@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,600;1,600&display=swap');
@import url("https://use.typekit.net/zrw7zmn.css");

:root {
  --gold: #cc9b2b;
  --green: #324325;
  --brick: #a74324;
  --white: #FBFCFC;
  --black: #170501;
  --shadow: rgba(23, 51, 1, 0.9);
  --main-header: Playfair Display, serif; 
  --header-letter-spacing: 0.05em;
  --body: acumin-pro-extra-condensed, sans-serif;
  --body-light: 300;
  --body-regular: 400;
  --body-letter-spacing: 0.1em;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  background-color: var(--white);
  font-family: var(--body);
  font-weight: var(--body-light);
  letter-spacing: var(--body-letter-spacing);
}

.grid-container {
  display: grid;
  grid-template-areas: "header"
  "main"
  "footer";
  grid-template-rows: 5rem 1fr 5rem;
  grid-template-columns: 1fr;
  height: 100vh;
  width: 100%;
  margin: 0;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
}

footer {
  grid-area: footer;
}


div.hero-image {
  background-image: url("../../images/klara-kulikova.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(0.5px);
  -webkit-filter: blur(0.5px);
  width: 90%;
  height: 73vh;
  margin: 0 auto;
}

div.intro-headline {
  max-width: 75%;
  margin: 0 auto;
  font-family: var(--main-header);
  letter-spacing: var(--header-letter-spacing);
  font-size: 2.75vw;
  text-align: center;
  padding: 1rem;
  color: var(--black);
  margin-bottom: 4rem;
}

div.intro-sections {
  max-width: 90%;
  margin: 0 auto;
}

section.intro-row {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  align-items: center;
}

div.intro-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 50vh;
  min-height: 40vh;
  grid-row: 1;
}

div.intro-image.image-left {
  grid-column: 1 / span 7;
}

div.intro-image.image-right {
  grid-column: 6 / -1;
}

div#intro-image--good-eats {
  background-image: url('../../images/spencer-davis.jpg');
}
div#intro-image--good-drinks {
  background-image: url('../../images/elevate.jpg');
}
div#intro-image--good-times {
  background-image: url('../../images/elevate-unsplash.jpg');
}

img.intro {
  max-width: 100%;
}

div.intro-text {
  background-color: var(--white);
  border: 2px var(--gold) solid;
  grid-row: 1;
  padding: 2rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

div.intro-text.text-right {
  grid-column: 7 / -1;
}
div.intro-text.text-left {
  grid-column: 1 / span 6;
}

.intro-text--heading,
.intro-text--body {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.intro-text--heading {
  font-family: var(--main-header);
  letter-spacing: var(--header-letter-spacing);
  font-style: italic;
}

.intro-text--link {
  margin-top: 2rem;
  text-decoration: none;
  font-family: var(--main-header);
  letter-spacing: var(--header-letter-spacing);
  background-color: var(--brick);
  padding: 1.5%;
  width: 35%;
  text-align: center;
  color: var(--white);
  font-style: italic;
  font-size: smaller;
}

.intro-text--link:hover {
  cursor: pointer;
  transform: translateX(10px); 
  font-size: larger;
  width: 40%;
}

@media (max-width: 600px) {
  div.hero-image {
    height: 65vh;
  }
  div.intro-headline {
    max-width: 90%;
    font-size: 7vw;
    padding: 0;
    padding-top: 0.75rem;
    margin-bottom: 1.5rem;
  }
  section.intro-sections {
    flex-direction: row;
  }
  div.intro-image.image-left {
    grid-column: 1 / span 11;
  }
  div.intro-image.image-right {
    grid-column: 2 / -1;
  }
  div.intro-text {
    padding: 1rem;
    font-size: larger;
  }
  div.intro-text.text-right {
    grid-column: 3 / -1;
  }
  div.intro-text.text-left {
    grid-column: 1 / span 10;
  }
  .intro-text--link {
    width: 60%;
  }
}

@media (min-width: 900px) {
    .intro-text--heading {
    font-size: 2.5vw;
  }
  
  .intro-text--body {
    font-size: 1.5vw;
  }
}


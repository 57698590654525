div.table-container {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
}

div.table-container > h2 {
    text-align: center;
    font-family: var(--main-header);
    font-style: italic;
}

.beer-table {
    width: 100%;
    margin-bottom: 4rem;
}

thead {
    font-size: larger;
    background-color: var(--gold);
    color: var(--white);
    width: 100%;
}

tr:hover {
    cursor: pointer;
}
tr.beer-table--row:hover {
    color: var(--brick);
}

@media (max-width: 415px) {
    div.table-container {
        max-width: 98vw;
        padding-right: 5px;
        padding-left: 5px;
    }

    .beer-table {
        max-width: 50%;
        font-size: smaller;
        margin-bottom: 1rem;
        line-height: 25px;
    }

    .up-next {
        max-width: 100%;

    }
    

    .beer-table > th {
        max-width: 90px;
    }

    .beer-table > td {
        font-size: smaller;
    }
}
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,600;1,600&display=swap);
@import url(https://use.typekit.net/zrw7zmn.css);
/* ----- Header Styling ----- */
header {
  grid-area: header;
  display: -webkit-flex;
  display: flex;
  background-color: var(--white);
  z-index: 500;
  font-family: var(--main-header);
  letter-spacing: var(--header-letter-spacing);
  font-size: 1.8vw;
  font-style: italic;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  min-height: 93px;
}

.nav-logo {
  margin: 2% 0 0 4.8%;
  max-width: 18%;
}


img.nav-brand {
  max-width: 60%;
  min-width: 80px;
  background-color: var(--white);
  border-radius: 0 0 20% 0;
  margin: 0 auto;
  padding: 0 1rem 1rem 1rem;
  z-index: 10000;
}

nav {
  width: 68%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  z-index: 1000;
}

.nav-link {
  color: var(--gold);
  text-decoration: none;
  padding-top: 1rem;
  /* text-align: center; */
  text-align: right;
  z-index: 50000;
  margin-top: .28rem;
}

.nav-link:hover {
  color: var(--green);
  font-weight: 800;
}

.nav-active {
  color: var(--brick);
}

.mobile-nav {
  display: none;
  margin-top: 33px;
  margin-right: 5%;
}

.nav-icon {
  color: var(--brick);
}

/* ----- Media Queries ----- */
@media (max-width: 600px) {
  .nav-logo {
    margin-top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  img.nav-brand {
    padding: 0.5rem;
    margin-left: 0;
  }
  nav {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    width: 100%;
    height: auto;
    top: 93px;
    left: -500px;
    opacity: 0;
    transition: all 0.75s ease;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-template-rows: repeat(3, auto);
  }
  .nav-active {
    background: var(--gold);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1000;
    -webkit-align-content: center;
            align-content: center;
    padding-left: 0;
  }
  .nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 10vw;
    padding: 30px 0;
    font-size: 7vw;
    color: var(--white);
  }
  .nav-link:hover {
    color: var(--green);
  }
  .mobile-nav {
    display: block;
  }
}
div.hero-image {
  background-image: url(/static/media/klara-kulikova.82cff813.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(0.5px);
  -webkit-filter: blur(0.5px);
  width: 90%;
  height: 73vh;
  margin: 0 auto;
}

div.intro-headline {
  max-width: 75%;
  margin: 0 auto;
  font-family: var(--main-header);
  letter-spacing: var(--header-letter-spacing);
  font-size: 2.75vw;
  text-align: center;
  padding: 1rem;
  color: var(--black);
  margin-bottom: 4rem;
}

div.intro-sections {
  max-width: 90%;
  margin: 0 auto;
}

section.intro-row {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  -webkit-align-items: center;
          align-items: center;
}

div.intro-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 50vh;
  min-height: 40vh;
  grid-row: 1;
}

div.intro-image.image-left {
  grid-column: 1 / span 7;
}

div.intro-image.image-right {
  grid-column: 6 / -1;
}

div#intro-image--good-eats {
  background-image: url(/static/media/spencer-davis.50fd3d2d.jpg);
}
div#intro-image--good-drinks {
  background-image: url(/static/media/elevate.b71ba454.jpg);
}
div#intro-image--good-times {
  background-image: url(/static/media/elevate-unsplash.6c14a5b1.jpg);
}

img.intro {
  max-width: 100%;
}

div.intro-text {
  background-color: var(--white);
  border: 2px var(--gold) solid;
  grid-row: 1;
  padding: 2rem;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

div.intro-text.text-right {
  grid-column: 7 / -1;
}
div.intro-text.text-left {
  grid-column: 1 / span 6;
}

.intro-text--heading,
.intro-text--body {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.intro-text--heading {
  font-family: var(--main-header);
  letter-spacing: var(--header-letter-spacing);
  font-style: italic;
}

.intro-text--link {
  margin-top: 2rem;
  text-decoration: none;
  font-family: var(--main-header);
  letter-spacing: var(--header-letter-spacing);
  background-color: var(--brick);
  padding: 1.5%;
  width: 35%;
  text-align: center;
  color: var(--white);
  font-style: italic;
  font-size: smaller;
}

.intro-text--link:hover {
  cursor: pointer;
  -webkit-transform: translateX(10px);
          transform: translateX(10px); 
  font-size: larger;
  width: 40%;
}

@media (max-width: 600px) {
  div.hero-image {
    height: 65vh;
  }
  div.intro-headline {
    max-width: 90%;
    font-size: 7vw;
    padding: 0;
    padding-top: 0.75rem;
    margin-bottom: 1.5rem;
  }
  section.intro-sections {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  div.intro-image.image-left {
    grid-column: 1 / span 11;
  }
  div.intro-image.image-right {
    grid-column: 2 / -1;
  }
  div.intro-text {
    padding: 1rem;
    font-size: larger;
  }
  div.intro-text.text-right {
    grid-column: 3 / -1;
  }
  div.intro-text.text-left {
    grid-column: 1 / span 10;
  }
  .intro-text--link {
    width: 60%;
  }
}

@media (min-width: 900px) {
    .intro-text--heading {
    font-size: 2.5vw;
  }
  
  .intro-text--body {
    font-size: 1.5vw;
  }
}


div.table-container {
    max-width: 90%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 1rem;
}

div.table-container > h2 {
    text-align: center;
    font-family: var(--main-header);
    font-style: italic;
}

.beer-table {
    width: 100%;
    margin-bottom: 4rem;
}

thead {
    font-size: larger;
    background-color: var(--gold);
    color: var(--white);
    width: 100%;
}

tr:hover {
    cursor: pointer;
}
tr.beer-table--row:hover {
    color: var(--brick);
}

@media (max-width: 415px) {
    div.table-container {
        max-width: 98vw;
        padding-right: 5px;
        padding-left: 5px;
    }

    .beer-table {
        max-width: 50%;
        font-size: smaller;
        margin-bottom: 1rem;
        line-height: 25px;
    }

    .up-next {
        max-width: 100%;

    }
    

    .beer-table > th {
        max-width: 90px;
    }

    .beer-table > td {
        font-size: smaller;
    }
}
div.contact {
  width: 90%;
  margin: auto;
  /* margin-top: 0.94rem; */
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

div.contact--info {
  width: 50%;
  padding: 1.5rem;
}

h2.contact--info-heading {
  font-family: var(--main-header);
  letter-spacing: var(--header-letter-spacing);
  font-size: larger;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
h3.contact--info-subheading {
  margin-bottom: 0;
}
h4.contact--info-subheading {
  font-size: larger;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}

hr {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.contact--info-inquiries {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contact--info-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

img.contact--info-image {
  width: 1rem;
  height: auto;
  margin-right: 0.7rem;
}

a.contact--info-phone {
  color: var(--black);
}

a.contact--info-phone:hover {
  color: var(--brick);
  /* font-size: larger; */
  font-weight: 700;
}

div.contact--map {
  width: 50%;
  height: 100%;
  justify-items: center;
}

iframe {
  margin-top: 10%;
  width: 100%;
  height: 80%;
  border: 0;
}

@media (max-width: 850px) {
  /* h2.contact--info-heading {
    font-size: large;
  }

  h4.contact--info-subheading {
    font-size: medium;
  } */
}

@media (max-width: 480px) {
  div.contact {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: auto;
  }
  div.contact--info,
  div.contact--map {
    width: 100%;
    padding: 0.7rem;
  }
    h2.contact--info-heading {
    font-size: x-large;
  }

  h4.contact--info-subheading {
    font-size: larger;
  }
  section.contact--info-inquiries {
    text-align: right;
  }
  div.contact--info-group {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  iframe {
    height: 300px;
    margin: 0;
  }
}
footer {
  /* margin-top: 1.75rem; */
  background-color: var(--gold);
  height: auto;
  /* display: flex;
  flex-direction: column; */
  margin-bottom: 0;
}

.footer-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: auto;
  margin-bottom: 0;
  height: auto;
  width: 100%;
  background-color: var(--gold);
  padding-left: 5%;
  padding-right: 5%;
  color: var(--white);
  font-weight: var(--body-light);
}

span.footer-content--column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 0.65rem;
  margin-bottom: 0.65rem;
}

a.order-phone {
  color: var(--white);
}

.order-phone:hover {
  color: var(--green);
  font-size: larger;
}

h4.footer-content--text {
  margin: 0;
  font-weight: var(--body-light);
  font-size: larger;
}

h4.footer-content--text > i {
  margin-right: 0.5rem;
}

a.social-link {
  margin-left: 1rem;
  color: var(--white);
}

a.social-link:hover {
  color: var(--green);
  font-size: larger;
}

.footer-credits {
  width: 100%;
  text-align: center;
  background-color: var(--gold);
}

.footer-credits--text {
  margin-top: 0px;
  padding-top: 2px;
  margin-bottom: 0;
  color: var(--white);
}

@media (max-width: 820px) {
  .footer-content {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding-bottom: 8px;
  }
  span.footer-content--column {
    -webkit-flex-basis: calc(50% - 12px);
            flex-basis: calc(50% - 12px);
    width: 50%;
  }
  a.social-link {
    margin-left: 0;
    margin-right: 1rem;
  }
  .footer-credits--text {
    padding-top: 0;
  }
}

@media (max-width: 480px) {
  .footer-content {
    -webkit-flex-direction: column;
            flex-direction: column;
    /* justify-content: center; */
    -webkit-align-items: center;
            align-items: center;
  }
  span.footer-content--column {
    display: unset;
    width: 100%;
    text-align: center;
  }
  a.social-link {
    margin-top: 5px;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-bottom: 0.75rem;
  }
}


:root {
  --gold: #cc9b2b;
  --green: #324325;
  --brick: #a74324;
  --white: #FBFCFC;
  --black: #170501;
  --shadow: rgba(23, 51, 1, 0.9);
  --main-header: Playfair Display, serif; 
  --header-letter-spacing: 0.05em;
  --body: acumin-pro-extra-condensed, sans-serif;
  --body-light: 300;
  --body-regular: 400;
  --body-letter-spacing: 0.1em;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  background-color: #FBFCFC;
  background-color: var(--white);
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-family: var(--body);
  font-weight: 300;
  font-weight: var(--body-light);
  letter-spacing: 0.1em;
  letter-spacing: var(--body-letter-spacing);
}

.grid-container {
  display: grid;
  grid-template-areas: "header"
  "main"
  "footer";
  grid-template-rows: 5rem 1fr 5rem;
  grid-template-columns: 1fr;
  height: 100vh;
  width: 100%;
  margin: 0;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
}

footer {
  grid-area: footer;
}



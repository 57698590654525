div.contact {
  width: 90%;
  margin: auto;
  /* margin-top: 0.94rem; */
  height: 100%;
  display: flex;
  align-items: center;
}

div.contact--info {
  width: 50%;
  padding: 1.5rem;
}

h2.contact--info-heading {
  font-family: var(--main-header);
  letter-spacing: var(--header-letter-spacing);
  font-size: larger;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
h3.contact--info-subheading {
  margin-bottom: 0;
}
h4.contact--info-subheading {
  font-size: larger;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}

hr {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.contact--info-inquiries {
  display: flex;
  flex-direction: column;
}

.contact--info-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

img.contact--info-image {
  width: 1rem;
  height: auto;
  margin-right: 0.7rem;
}

a.contact--info-phone {
  color: var(--black);
}

a.contact--info-phone:hover {
  color: var(--brick);
  /* font-size: larger; */
  font-weight: 700;
}

div.contact--map {
  width: 50%;
  height: 100%;
  justify-items: center;
}

iframe {
  margin-top: 10%;
  width: 100%;
  height: 80%;
  border: 0;
}

@media (max-width: 850px) {
  /* h2.contact--info-heading {
    font-size: large;
  }

  h4.contact--info-subheading {
    font-size: medium;
  } */
}

@media (max-width: 480px) {
  div.contact {
    flex-direction: column;
    height: auto;
  }
  div.contact--info,
  div.contact--map {
    width: 100%;
    padding: 0.7rem;
  }
    h2.contact--info-heading {
    font-size: x-large;
  }

  h4.contact--info-subheading {
    font-size: larger;
  }
  section.contact--info-inquiries {
    text-align: right;
  }
  div.contact--info-group {
    justify-content: flex-end;
  }
  iframe {
    height: 300px;
    margin: 0;
  }
}